<template>
  <el-dialog title="添加财务人员" v-model="view" width="500px">
    <el-form ref="form" label-width="100px" class="el-dialog-div">
      <el-form-item label="姓名" :required="true">
        <el-input v-model="form.Name"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" :required="true">
        <el-input v-model="form.Mobile"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
                <span class="dialog-footer">
                    <el-button @click="view = false">取 消</el-button>
                    <el-button type="primary" @click="saveAdd">确定</el-button>
                </span>
    </template>
  </el-dialog>
</template>

<script>
import {AddUser} from '../../../api/ProjectAdmin/Finance'

export default {
  data() {
    return {
      view: false,
      query: {
        App_id: '',
        ComName: '',
      },
      form: {
        Name: '',
        Mobile: ''
      },

    }
  },
  methods: {
    saveAdd() {
      let App_id = this.query.App_id
      let Name = this.form.Name
      let Mobile = this.form.Mobile

      AddUser({ App_id, Mobile, Name}).then(res => {
        if (res.code == 0) {
          this.$parent.FindCommittee()
          this.view = false;
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      })
    },
    open(App_id, ComName) {
      this.form = {}
      this.view = true
      this.query.App_id = App_id
      this.query.ComName = ComName
    },
    close() {
      this.form = {}
      this.App_id = ''
    }
  }
}
</script>

<style scoped>

</style>